<template>
  <v-card>
    <v-card-title class="pt-8">
      Daily Meter Readings
      <v-container>
        <v-container>
          <v-row>
            <v-col class="col-md-12">
              <table-filter
                :table-name="'daily meter readings'"
                :default-date-picker-date="defaultDatePickerDate"
                @filter="applyFilters"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="dailyMeterReadings"
      :loading="isMeterReadingsLoading"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:progress>
        <v-progress-linear
          indeterminate
          class="mt-2"
          rounded
        ></v-progress-linear>
        <p class="text-center mt-1">
          Loading...Please wait
        </p>
      </template>
      <template v-slot:body="{ items }">
        <tbody
          v-if="dailyMeterReadings && dailyMeterReadings.length > 0"
        >
          <tr
            v-for="reading in items"
            :key="reading.id"
          >
            <td>{{ reading.reading }}</td>
            <td>{{ reading.created_at | formatDay }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-show="!isMeterReadingsLoading">
            <td
              :colspan="headers.length"
              style="text-align: center"
            >
              Daily meter readings not found
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <data-table-pagination
      :page="pagination.page"
      :page-count="pagination.pageCount"
      @page-change="onPageChange"
      @items-per-page-change="onItemsPerPageChange"
    />
  </v-card>
</template>
<script>
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import TableFilter from '../partials/table-filter/index.vue'
import numberToWords from '@/mixins/numberToWords'
import printHtml from '@/mixins/printHtml'
import DataTablePagination from '@/components/partials/DataTablePagination.vue'

export default {
  components: {
    TableFilter,
    DataTablePagination,
  },
  mixins: [numberToWords, printHtml],
  props: {
    meterId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      today: moment().format('MMMM Do, YYYY'),
      sortBy: ['created_at'],
      sortDesc: [true],
      search: '',
      headers: [
        {
          text: 'Reading',
          value: 'reading',
          sortable: false,
        },
        { text: 'Date', value: 'created_at' },
      ],
      isMeterReadingsLoading: true,
      isMeterReadingsRefreshing: false,
      dailyMeterReadings: [],
      dailyMeterReadingsAverage: '',
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
      },
      filters: {
        dates: {
          fromDate: '',
          toDate: '',
        },
      },
      defaultDatePickerDate: 'Last 30 days',
      showReceiptDialog: false,
    }
  },
  computed: {
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
    refreshTable() {
      return `${this.readingUrl}|${this.search}|${this.sortBy}|${this.sortDesc}`
    },
  },
  watch: {
    refreshTable() {
      this.getMeterReadings()
    },
    activeSearchFilter() {
      if (this.search !== '') {
        this.getMeterReadings()
      }
    },
  },
  mounted() {
    this.getMeterReadings()
  },
  methods: {
    getMeterReadings: _.debounce(function () {
      this.isMeterReadingsLoading = true
      axios
        .get(this.constructCompleteMeterReadingUrl())
        .then(response => {
          if (response.data.data.length > 0) {
            this.dailyMeterReadings = response.data.data
            this.pagination.page = response.data.current_page
            this.pagination.pageCount = response.data.last_page
            this.pagination.itemsPerPage = response.data.per_page
          } else {
            console.log('no meter readings')
          }
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        }).finally(() => {
          this.isMeterReadingsLoading = false
          this.isMeterReadingsRefreshing = false
        })
    }, 500),
    refreshMeterReadings() {
      this.isMeterReadingsRefreshing = true
      this.getMeterReadings()
    },
    onPageChange(page) {
      this.pagination.page = page
      this.getMeterReadings()
    },
    onItemsPerPageChange(itemsPerPage) {
      this.pagination.itemsPerPage = itemsPerPage
      this.getMeterReadings()
    },
    applyFilters(filters) {
      this.filters = filters
      this.getMeterReadings()
    },
    constructCompleteMeterReadingUrl() {
      return `daily-meter-readings/${this.meterId}?fromDate=${this.filters.dates.fromDate}&toDate=${this.filters.dates.toDate}&sortBy=${this.sortBy[0]}&sortOrder=${this.sortOrder}&page=${this.pagination.page}&perPage=${this.pagination.itemsPerPage}`
    },
  },
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>
